import { StaticImage } from "gatsby-plugin-image";
import React from "react";

function ShareLinks(p: { url: string }) {
  const handleCopyLink = () => {
    navigator.clipboard.writeText(p.url);
  };

  return (
    <>
      <a href={`https://www.facebook.com/sharer/sharer.php?u=${p.url}`}>
        <StaticImage src="../../../../images/fb_primary.svg" alt="fbicon" />
      </a>
      <a href={`https://www.linkedin.com/sharing/share-offsite/?url=${p.url}`}>
        <StaticImage src="../../../../images/linkedin_primary.svg" alt="linkedinicon" />
      </a>
      <a href={`https://twitter.com/intent/tweet?text=${p.url}`}>
        <StaticImage src="../../../../images/twitter_primary.svg" alt="twittericon" />
      </a>
      <div className="cursor-pointer" onClick={handleCopyLink}>
        <StaticImage src="../../../../images/Link_primary.svg" alt="linkprimaryicon" />
      </div>
    </>
  );
}

export default ShareLinks;
