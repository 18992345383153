import { StaticImage } from "gatsby-plugin-image";
import React, { useContext } from "react";
import ModalContext from "../context/ModalContext";

const ContactSlideoutLink = ({ text }) => {
  const { setModalvisible, setPopupContent } = useContext(ModalContext);

  return (
    <span
      onClick={() => {
        setModalvisible(true);
        setPopupContent("steps");
      }}
      className="text-blue-500 cursor-pointer"
    >
      {text}
    </span>
  );
};

export default ContactSlideoutLink;
