import { StaticImage } from "gatsby-plugin-image";
import React from "react";

const AuthorAvatar = () => {
  return (
    <StaticImage
      src="../images/selby.png"
      alt="profile"
      width={48}
      height={48}
      class="rounded-[50%]"
    />
  );
};

export default AuthorAvatar;
